header.md-header {
  background: transparent;
  height: 100px;
  object-fit: scale-down;
  display: flex;
  justify-items: center;
  align-items: center;
  .mims-brand {
    width: 230px;
    object-fit: scale-down;
  }
}

.ant-btn-primary {
  background-color: #503291 !important;
  border-color: #503291 !important;

}



.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-up.on,
.ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner
  .ant-table-column-sorter-down.on {
  color: #eb3c96 !important;
}

.ant-layout.search-layout {
  width: 100vw;
  max-width: 100%;
  align-items: center;
  height: 100vh;
  background-image: url("../assets/images/fl-scheduler-background.png");
  background-position: center center;
  background-size: contain;
  @media (max-width: 768px) {
    height: auto;
  }
  // @media (max-width: 768px) {
  //   background-size: contain;
  // }
  background-repeat: no-repeat;
  box-shadow: inset 0px -400px 700px -350px #fff;
  .search-content {
    padding: 0 50px;

    header.md-header {
      padding: 0 10px;
      @media (max-width: 768px) {
        display: none;
      }
    }
    .page-title {
      margin-top: 10px;
      margin-bottom: 5px;
      letter-spacing: 0.1rem;
      @media (max-width: 768px) {
        padding: 100px 0 0 0 !important;
        margin: 0;
      }
    }
    .page-description {
      margin-bottom: 37px;
      letter-spacing: 0.1rem;
      @media (max-width: 768px) {
        padding: 10px 0 !important;
        margin: 0;
      }
    }

    .page-title,
    .page-description {
      padding: 0 35px;
    }
  }

  @media (max-width: 768px) {
    .ant-form {
      > div {
        > div:last-child {
          text-align: left;
          margin-top: 20px;
        }
      }
    }
  }
}

.ant-layout.search-result-layout {
  display: flex;
  align-items: center;
  .search-content {
    padding: 0px;

    header.md-header {
      padding: 0 10px;

      .mims-brand {
        margin: 0px 50px;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }

    .page-title {
      margin: 0;
      letter-spacing: 0.1rem;
    }
    .page-description {
      margin-bottom: 20px;
    }

    .ant-card.search-results-card {
      width: 100%;
      border-radius: 0px;
      border: transparent;
      box-shadow: inherit;

      padding: 0px 250px 20px 250px;
      box-shadow: 0 4px 4px #00000026;
      @media (max-width: 768px) {
        padding: 0px;
      }
    }
  }
}

.ant-card.search-card {
  width: calc(100% - 23%);
  border: transparent;
  box-shadow: 0 4px 15px #00000026;
  padding: 0px 30px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    width: calc(100%);
    margin-top: 20px;
    margin-bottom: 50px;
  }

  .ant-form-item-label {
    > label {
      font-size: 0.8rem;
      font-weight: bold;
    }
  }
  .tip {
    color: #b0b0b0;
    font-size: 0.9rem;
    margin: 0;
  }
  input {
    height: 45px;
    // &:hover,
    // &:focus {
    //   border-color: #54c8bf;
    //   box-shadow: 1px solid #54c8bf !important;
    //   outline: none !important;
    // }
  }
  button {
    padding: 0px 50px;
    font-weight: 500;
    letter-spacing: 0.1rem;
    cursor: pointer;
  }
}

.layout {
  .sub-layout {
    overflow: hidden;
    position: relative;

    .sub-layout-content {
      // padding: 0px 50px;
    }

    .page-title {
      color: #151b35;
    }
    .page-description {
      color: #151b35;
    }
  }
}
.search-result-item-container {
  background: #f8f8f8;
  padding-bottom: 50px;
  .results {
    > div:nth-child(even) {
      display: flex;
      justify-content: flex-end;
    }
  }
  .show-more {
    margin-top: 50px;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
